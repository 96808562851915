<template>
  <!-- begin::kb-header -->
  <header class="kb-header" id="kb-header">
    <div class="header-component">
      <!-- header-title -->
      <div class="header-column header-title">
        입실 날짜 선택
      </div>
      <!-- //header-title -->
      <div class="header-column header-util left">
        <div class="util util-back">
          <a href="javascript:" class="util-actions util-actions-back" @click="goBack">
            <i class="icon-history-back"></i>
          </a>
        </div>
      </div>
    </div>
  </header>
  <!-- end::kb-header -->

  <!-- begin::kb-main -->
  <main class="kb-main" id="kb-facility">
    <!-- main-content -->
    <div class="main-content main-component">
      <div class="schedule-container pt-5">
        <div class="calendar-picker-container">
          <!-- calendar-picker-container > calendar-picker -->
          <div class="calendar-picker">
            <div class="picker-body">
              <div class="datepicker-header">
                <button class="kb-btn-datepicker-nav kb-btn-datepicker-prev" @click="changeMonth(false)">{{ prevYearMonth }}</button>
                <div class="title">{{ currentYearMonth }}</div>
                <button class="kb-btn-datepicker-nav kb-btn-datepicker-next" @click="changeMonth(true)">{{ nextYearMonth }}</button>
              </div>
              <div class="datepicker-table datepicker-table-week">
                <table>
                  <thead>
                  <tr>
                    <th>일</th>
                    <th>월</th>
                    <th>화</th>
                    <th>수</th>
                    <th>목</th>
                    <th>금</th>
                    <th>토</th>
                  </tr>
                  </thead>

                  <tbody v-if="data.isDayShow">
                  <tr v-for="(week, idx) in currentWeeks" :key="idx">
                    <td v-for="(day, i) in week" :key="`${idx}-${i}`">
                      <template v-if="day.month === currentMonth">

<!--
                        <button class="kb-btn-day" :class="{ 'is-active' : startDt.ymd === day.ymd }" @click="handleSelectDate(day)" >
                          <span class="text">{{ day.day }}</span>
                        </button>
-->

                        <template v-if="data.isClick">
                          <div v-if="roomTy != 'LTR'">
                            <button type="button" class="kb-btn-day" :class="{ 'is-active' : getDayActive(day), 'disabled' : isPastDay(day) || getCalendarText(day) == '미운영' || getCalendarText(day) == '신청불가' || getCalendarText(day) == '마감', 'day-red' : isWeekend(day) }"  @click="handleSelectDate(day)" :disabled="isPastDay(day) || getCalendarText(day) == '미운영' || getCalendarText(day) == '신청불가' || getCalendarText(day) == '마감'">
                              <span class="text" :class="{ 'text-red' : isWeekend(day) }">{{ day.day }}</span>
                            </button>
                            <span v-if="isPastDay(day)" class="status-text">신청 불가</span>
                            <span v-else class="status-text">{{  getCalendarText(day) }}</span>
                          </div>

                          <div v-if="roomTy == 'LTR'">
                            <button type="button" class="kb-btn-day" :class="{ 'is-active' : getDayActive(day), 'disabled' : !data.isClick ||  isDisabledLTR(day) || getCalendarText(day) == '미운영' || getCalendarText(day) == '신청불가' || getCalendarText(day) == '마감', 'day-red' : isWeekend(day) }"  @click="handleSelectDate(day)" :disabled="isDisabledLTR(day) || getCalendarText(day) == '미운영' || getCalendarText(day) == '신청불가' || getCalendarText(day) == '마감'">
                              <span class="text" :class="{ 'text-red' : isWeekend(day) }">{{ day.day }}</span>
                            </button>
                            <span v-if="isDisabledLTR(day)" class="status-text">신청 불가</span>
                            <span v-else class="status-text">{{  getCalendarText(day) }}</span>
                          </div>
                        </template>
                        <template v-else>
                          <span type="button" class="kb-btn-day" disabled>
                            <span class="text" :class="{ 'text-red' : isWeekend(day) }">{{ day.day }}</span>
                          </span>
                          <span class="status-text">조회중</span>
                        </template>

                      </template>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="page-buttons"><button class="kb-btn kb-btn-primary" @click="selectDate">적용</button></div>
      </div>
    </div>
    <!-- //main-content -->
  </main>
  <!-- end::kb-main -->
</template>
<script>
import {computed, reactive, ref, onMounted} from 'vue';
import {dateToDateFormat, getCalendar, lengthCheck, ymdStrToDate, ymdStrToDateFormat} from '@/assets/js/util';
import {ACT_GET_TRNCT_OPER_YMD_CALENDER_CHECK} from "@/store/modules/trnct/trnct";
import {useStore} from "vuex";
import {useAlert} from "@/assets/js/modules/common/alert";
import {ACT_SET_TRNCT_APLY_MOBILE_INFO} from "@/store/modules/trnct/trnct";
import {useRouter} from "vue-router";

export default {
  name: 'DailyMobileApplyStep0Date',
  components: {

  },
  setup() {
    onMounted(() => {
      init();
      getCurrentYearMonthOperYmd();
    })

    const store = useStore();
    const router = useRouter();
    const {showMessage} = useAlert();

    const currentDate = new Date();
    // 현재 연월일 데이트 피커 데이터
    const currentYear = ref(currentDate.getFullYear());
    const currentMonth = ref(currentDate.getMonth() + 1);
    // const currentDay = ref(currentDate.getDate());

    const currentYearMonthOperYmd = ref(null);
    //여기서 디비에서 한달치 운영데이터를 가져 와야 한다.

    const data = reactive({
      isDayShow : false,
      isClick : false
    });

    // 오늘 연 월 일
    const today = reactive({
      year: currentDate.getFullYear(),
      month: (currentDate.getMonth() + 1),
      day: currentDate.getDate(),
    });

    // 이번주 데이터
    const currentWeeks = computed(() => getCalendar(currentYear.value, currentMonth.value));

    // Datepicker range 일때 두번째 달력 뿌릴 데이터
    // 다음 달이 내년일 경우 연도+1 아니면 이번년도
    const getNextYear = computed(() => {
      if (currentMonth.value === 12) {
        return currentYear.value + 1;
      } else {
        return currentYear.value;
      }
    });

    // 다음달이 내년일 경우 1월 아니면 다음달
    const getNextMonth = computed(() => {
      if (currentMonth.value === 12) {
        return 1;
      } else {
        return currentMonth.value + 1;
      }
    });

    const nextYear = ref(getNextYear.value);
    const nextMonth = ref(getNextMonth.value);

    // 2번째 달력의 주 정보
    const nextWeeks = computed(() => getCalendar(getNextYear.value, getNextMonth.value));

    const isOpen = ref(false);
    // const isLoading = ref(false);

    const todayYmd = `${today.year}${('0' + today.month).slice(-2)}${('0' + today.day).slice(-2)}`;

    const startDt = ref('');
    const endDt = ref('');
    const roomTy = ref(store.state.trnct.mobileApplyInfo.room);

    const currentYearMonth = computed(() => {
      return `${currentYear.value}년 ${currentMonth.value}월`;
      /*if (currentMonth.value === 12) {
        return `${currentYear.value + 1}년 1월`;
      } else {
        return `${currentYear.value}년 ${currentMonth.value}월`;
      }*/
    });

    const nextYearMonth = computed(() => {
      if (currentMonth.value === 12) {
        return `${currentYear.value + 1}년 1월`;
      } else {
        return `${currentYear.value}년 ${currentMonth.value + 1}월`;
      }
    });

    const prevYearMonth = computed(() => {
      if (currentMonth.value === 1) {
        return `${currentYear.value - 1}년 12월`;
      } else {
        return `${currentYear.value}년 ${currentMonth.value - 1}월`;
      }
    });

    const session = computed(() => store.state.auth.session);

    const imsiUserList = ref(['S017638',
      '1516265',
      '1533872',
      '1626091',
      '1626835',
      '1628225',
      '1629130',
      '1630015',
      '1632414',
      '1632934',
      '1633091',
      '1633930',
      '1650265',
      '1651212',
      '1652204',
      '1652421',
      '1653167',
      '1653349',
      '1653886',
      '1907735',
      '1908883',
      '2204585',
      '2207321',
      '2313782',
      '2315169',
      '2334686',
      '2349662',
      '2350080',
      '2350660',
      '2350781',
      '2351114',
      '2535288',
      '2540759',
      '2542854',
      '2769985',
      '2808347',
      '2828259',
      '2830018',
      '2830967',
      '2832707',
      '2833596',
      '2833781',
      '2835535',
      '2855524',
      '3160596',
      '3170788',
      '3171749',
      '3171770',
      '3172008',
      '3308687',
      '3326951',
      '3331340',
      '3331643',
      '3331873',
      '3331920',
      '3826701',
      '3828945',
      '3831350',
      '3831580',
      '3835230',
      '3900156',
      '5030611',
      '5029523',
      '5041519',
      '5237386',
      '5030687',
      '5028133',
      'S017335'
    ]);

    const monthDiff = () => {

      let year = new Date().getFullYear() * 12;
      let month = new Date().getMonth() + 1;
      let year1;
      let month1;
      if (currentMonth.value === 12) {
        year1 = ( currentYear.value + 1 ) * 12;
        month1 = 1
      }else{
        year1 = currentYear.value * 12
        month1 = currentMonth.value + 1
      }
      let diff = ( year1 + month1 ) - ( year + month )

      console.log('diff', diff);
      if(diff >= 3){
        if(imsiUserList.value.includes(store.state.auth.session.lrnerId)){
          return true;
        }
        return false;
      }
      return true;
    }

    // 다음, 이전 연 월 버튼 기능
    const changeMonth = async (isNext) => {
      if (isNext) {
        if(monthDiff()){
          if (currentMonth.value === 12) {
            currentYear.value++;
            currentMonth.value = 1;
          } else {
            currentMonth.value++;
          }

          if (nextMonth.value === 12) {
            nextYear.value++;
            nextMonth.value = 1;
          } else {
            nextMonth.value++;
          }
        }else{
          //do nothing
          showMessage('신청기간이 아닙니다. 연수원관리자에게 문의하세요.');

        }


      } else {
        if (currentMonth.value === 1) {
          currentYear.value--;
          currentMonth.value = 12;
        } else {
          currentMonth.value--;
        }

        if (nextMonth.value === 1) {
          nextYear.value--;
          nextMonth.value = 12;
        } else {
          nextMonth.value--;
        }
      }

      await getCurrentYearMonthOperYmd();
    };

    const init = () => {
      if(store.state.trnct.mobileApplyInfo.startDt){
        currentYear.value = store.state.trnct.mobileApplyInfo.startDt.year;
        currentMonth.value = store.state.trnct.mobileApplyInfo.startDt.month;
        startDt.value = store.state.trnct.mobileApplyInfo.startDt;
      }else{
        currentYear.value = currentDate.getFullYear();
        currentMonth.value = currentDate.getMonth() + 1;
      }
      nextYear.value = getNextYear.value;
      nextMonth.value = getNextMonth.value;
    };

    const isActiveCheckOutDate = ref(false);

    const handleSelectDate = (date) => {
          console.log(date);
          if(data.isClick) {
            startDt.value = date;
          }
    };

    const isPastDay = (date) => {
      if (currentDate < ymdStrToDate(date.ymd)) return false;
      return true;
    };

    const isDisabledLTR = (date) => {
      if (currentDate < ymdStrToDate(date.ymd)){
        const week = ['일', '월', '화', '수', '목', '금', '토'];
        const dayOfWeek = week[new Date(date.ymdformat).getDay()];
        if(dayOfWeek === '금' || dayOfWeek === '토' || dayOfWeek === '일') return true;
        return false;
      }else{
        return true;
      }
    };

    const isWeekend = (date) => {
      const week = ['일', '월', '화', '수', '목', '금', '토'];

      const dayOfWeek = week[new Date(date.ymdformat).getDay()];

      if(dayOfWeek === '토' || dayOfWeek === '일') return true;

      return false;
    }


    const getCurrentYearMonthOperYmd = async () => {

      data.isClick = false;

      console.log(currentYear.value+'-'+currentMonth.value);
      await store.dispatch(`trnct/${ACT_GET_TRNCT_OPER_YMD_CALENDER_CHECK}`,{
        trnctMstSn : store.state.trnct.mobileApplyInfo.venue,
        rsvtAplyDd : currentYear.value+'-'+ ( currentMonth.value <= 9 ? "0"+currentMonth.value : currentMonth.value ),
        prsnBrcYn : 'Y', //개인으로 하드 코딩
        roomTy : store.state.trnct.mobileApplyInfo.room //룸타임 앞에서 받아 와야 한다/
      }).then(res => {
        if(lengthCheck(res)){
          console.log('getTrnctOperYmdCheck...',res);
          currentYearMonthOperYmd.value = res.items[0];
          data.isDayShow = true;
          data.isClick = true;
          /*usePeridOption.value = [];
          for(let i = 0 ; i < res.items.length ;i++){
            usePeridOption.value.push(res.items[i]);
          }*/

        }
      }).catch((e) => {console.error(e);})
    }

    const getCalendarText = (date) =>{
      return currentYearMonthOperYmd.value[date.ymdformat];
    }

    const getDayActive = (date) => {
      if(startDt.value.ymdformat === date.ymdformat)
        return true
      else
        return false
    }

    const selectDate = () => {
      store.commit(`trnct/${ACT_SET_TRNCT_APLY_MOBILE_INFO}`, {startDt: startDt.value, rsvtAplyDd: startDt.value.ymdformat});
      router.go(-1);
    }

    const goBack = () => {
      router.go(-1);
    }

    return {
      ymdStrToDate,
      dateToDateFormat,
      ymdStrToDateFormat,

      today,
      isOpen,

      currentYear,
      currentMonth,
      // currentDay,
      currentWeeks,

      nextYear,
      nextMonth,
      nextWeeks,

      todayYmd,
      currentYearMonth,
      nextYearMonth,
      prevYearMonth,
      changeMonth,

      handleSelectDate,
      isActiveCheckOutDate,

      isPastDay,

      startDt,
      endDt,
      isWeekend,
      getCurrentYearMonthOperYmd,
      currentYearMonthOperYmd,
      getCalendarText,
      data,
      getDayActive,
      selectDate,
      session,
      roomTy,
      isDisabledLTR,
      goBack
    };
  },
};
</script>